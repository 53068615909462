import { getAuthToken } from '../App';

const reactAppEnv = process.env.REACT_APP_ENV;
const apiBaseUrl = process.env.REACT_APP_API_URL || '';

/** Creates API request object
 * @param {string} method - Request method
 * @param {string} urlSufix - Individual API endpoint
 * @param {Object} data - Request object data
 * @param {string} contentType - Request content type
 * @param {string} version - API version (default is 'v1')
 * @return {Object} Request object
 */
const apiRequest = (
  method = 'GET',
  urlSufix = '',
  data = {},
  contentType = 'application/json',
  version = 'v1',
  extraHeaders = {}
) => {
  const authToken = reactAppEnv === 'TEST' ? '' : getAuthToken();

  return {
    method: method,
    url: `${apiBaseUrl}${version}/${urlSufix}`,
    headers: {
      'Content-Type': contentType,
      Authorization: authToken ? `Token ${authToken}` : '',
      ...extraHeaders
    },
    data: data
  };
};

export default apiRequest;
